
import logoSrc from '~/assets/img/logo.svg';

export default {
  name: 'main-logo',
  data() {
    return {
      logoSrc,
    };
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
  },
};
